import logo from './logo.svg';
import './App.css';
import React from 'react';
import {Parallax, ParallaxLayer} from 'react-spring/renderprops-addons'

const url = (name, wrap = false) => `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`
const urlJpg = (name, wrap = false) => `${wrap ? 'url(' : ''}../img/${name}.jpg${wrap ? ')' : ''}`
const urlJpeg = (name, wrap = false) => `${wrap ? 'url(' : ''}../img/${name}.jpeg${wrap ? ')' : ''}`
const urlPng = (name, wrap = false) => `${wrap ? 'url(' : ''}../img/${name}.png${wrap ? ')' : ''}`
const img1 = `../img/2014-1.jpg`

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Parallax ref={ref => (React.useRef = ref)} pages={9}>
        <ParallaxLayer offset={1} speed={1} style={{ backgroundColor: '#282c34' }} />
        <ParallaxLayer offset={2} speed={1} style={{ backgroundColor: '#303842' }} />
        <ParallaxLayer offset={3} speed={1} style={{ backgroundColor: '#40505e' }} />
        <ParallaxLayer offset={4} speed={1} style={{ backgroundColor: '#485c6d' }} />
        <ParallaxLayer offset={5} speed={1} style={{ backgroundColor: '#587489' }} />
        <ParallaxLayer offset={6} speed={1} style={{ backgroundColor: '#5f8097' }} />
        <ParallaxLayer offset={7} speed={1} style={{ backgroundColor: '#6f98b4' }} />
        <ParallaxLayer offset={8} speed={1} style={{ backgroundColor: '#7fb0d0' }} />
        <ParallaxLayer offset={9} speed={1} style={{ backgroundColor: '#87BCDE' }} />

        <ParallaxLayer offset={0.9999} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1.4} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-4')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-6')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2.05} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2015-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2014-5')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2015-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2016-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2016-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2016-5')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={3.35} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2016-4')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2016-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3.9999} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-4')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={4.27} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-6')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-7')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2017-8')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={4.9999} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2018-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2018-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={5.20} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2018-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={5.9999} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={6.08} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-4')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-5')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-6')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={6.35} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-7')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-8')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2019-9')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={6.99999} speed={0.1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-4')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-5')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={7.33} speed={0.3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpeg('2020-7')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2020-8')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={7.99999} speed={0.2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div class="wrapper">
            <div class="item">
              <div class="polaroid">
                <img src={urlJpg('2021-1')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlPng('2021-2')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
            <div class="item">
              <div class="polaroid">
                <img src={urlPng('2021-3')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />
              </div>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={0} factor={9} style={{ backgroundImage: url('stars', true), backgroundSize: 'cover' }} />

        <ParallaxLayer offset={1.3} speed={-0.7} style={{ pointerEvents: 'none' }}>
          <img src={url('satellite4')} style={{ width: '15%', marginLeft: '70%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={0.8} style={{ opacity: 0.01 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={5.75} speed={0.5} style={{ opacity: 0.02 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={0.2} style={{ opacity: 0.04 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={5.6} speed={-0.1} style={{ opacity: 0.08 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={6.6} speed={0.4} style={{ opacity: 0.1 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={7} speed={0.8} style={{ opacity: 0.1 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={7.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={7} speed={0.2} style={{ opacity: 0.2 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={7.6} speed={-0.1} style={{ opacity: 0.4 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={8.6} speed={0.4} style={{ opacity: 0.6 }}>
          <img src={url('cloud')} style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
          <img src={url('cloud')} style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={8.5} speed={-0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', pointerEvents: 'none' }}>
          <img src={url('earth')} style={{ width: '60%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={0}
          speed={0.1}
          onClick={() => React.useRef.scrollTo(1)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={urlPng('title-gonzalez-timeline')} style={{ width: '60%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={0.7}
          speed={0.1}
          onClick={() => React.useRef.scrollTo(2)}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={urlPng('title-2014')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={1.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(3)}>
          <img src={urlPng('title-2015')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={2.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(4)}>
          <img src={urlPng('title-2016')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={3.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(5)}>
          <img src={urlPng('title-2017')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={4.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(6)}>
          <img src={urlPng('title-2018')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={5.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(7)}>
          <img src={urlPng('title-2019')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={6.7}
          speed={0.1}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(8)}>
          <img src={urlPng('title-2020')} style={{ width: '50%' }} />
        </ParallaxLayer>

        <ParallaxLayer
          offset={7.6}
          speed={-0}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          onClick={() => React.useRef.scrollTo(0)}>
          <img src={urlPng('title-2021')} style={{ width: '50%' }} />
        </ParallaxLayer>
      </Parallax>
      </header>
    </div>
  );
}

export default App;
